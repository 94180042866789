import React from "react"
import { Link } from "gatsby"


class MenuPrimary extends React.Component {

    render() {

        return (
            <div className="menu-primary">
                 <ul>
                     <li><Link to="/" onClick = {this.props.closeSidebar}>Home</Link></li>
                     <li><Link to="/#features" onClick = {this.props.closeSidebar}>Continuous Monitoring</Link></li>
                     <li><Link to="/tot-life" onClick = {this.props.closeSidebar}>Tot-Life</Link></li>
                     <li><Link to="/support" onClick = {this.props.closeSidebar}>Help Center</Link></li>
                 </ul>
            </div>
        )
    }
}


export default MenuPrimary;