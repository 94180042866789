import React from "react"
import "./footerContent.scss"
import SafetyStatement from "../sectionSafetyStatement/SectionSafetyStatement"
import logo from "../../assets/images/logo/welltot_logo.svg"
import MenuPrimary from "../menuPrimary/MenuPrimary";
import MenuLegal from "../menuLegal/MenuLegal";


class FooterContent extends React.Component {

    render() {
        var currentYear = new Date().getFullYear();

        return (
            <div className="footer-content">

                <div className="footerSafetyStatement">
                    <SafetyStatement/>
                </div>
                <div className="footerLogo">
                    <img src={logo} alt="AffirmXH Logo" />
                </div>


                <div className="footerPrimaryMenu">
                    <MenuPrimary/>
                </div>

                <div className="footerSecondaryMenu">
                    <MenuLegal/>
                </div>

                <div className="footerDisclaimers">
                    <p><small>Content on this site are for investor purposes only.<br/>Copyright&copy; {currentYear} AffirmXH. All rights reserved.</small></p>
                </div>
            </div>
        )
    }
}


export default FooterContent;
