import React from "react"
import {StaticQuery, graphql, PageProps} from "gatsby";


const SectionSafetyStatement = (props: PageProps) => {
     return (
            <div className="sectionSafetyStatement">
                <StaticQuery
                    query={graphql`
                        query SectionSafetyStatement {
                          prismicWelltotIfuInstruction(uid: {eq: "safety-statement"}) {
                            data {
                              lbl
                              body {
                                html
                              }
                            }
                          }
                        }
                      `}
                    render={data => (
                        <div dangerouslySetInnerHTML={{ __html: data?.prismicWelltotIfuInstruction?.data?.body?.html }} ></div>
                    )}
                />
            </div>
        )
}




export default SectionSafetyStatement;