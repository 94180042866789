import React from "react"
import { Link } from "gatsby"
import "./headerContent.scss"
import Sidebar from "../sidebar/Sidebar"
import MenuPrimary from "../menuPrimary/MenuPrimary"
import logo from "../../assets/images/logo/welltot_logo.svg"


class HeaderContent extends React.Component {

  render() {

    return (
      <div className="header-content">
          <div className="header-logo">
            <Link to="/"><img src={logo} alt="Welltot Logo" /></Link>
          </div>
          <div className="header-menu-primary">
              <MenuPrimary/>
          </div>
          <div className="header-sidebar">
              <Sidebar/>
          </div>
      </div>
    )
  }
}

export default HeaderContent;